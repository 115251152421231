import React from "react"
import { withCatalogueHero } from "./withCatalogueHero"
import { CatalogueGridCard } from "../CatalogueGrid/Card/CatalogueGridCard"

export const CatalogueHero = withCatalogueHero(({ card, handlePromoClick }) => (
  <div>
    <CatalogueGridCard card={card} handlePromoClick={handlePromoClick} />
  </div>
))

export default CatalogueHero
