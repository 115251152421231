import React from "react"
import { withCatalogueGridCardProduct } from "./withCatalogueGridCardProduct"
import { Content, MobileDotWrapper, MobileDot } from "../CatalogueGridCardStyles"
import { QuickView } from "../../../../Product/QuickView/QuickView"
import { Link } from "gatsby"

export const CatalogueGridCardProduct = withCatalogueGridCardProduct(
  ({
    top,
    left,
    mobileTop,
    mobileLeft,
    handle,
    parentProductHandle,
    isMobile,
    url,
  }) => (
    <Content
      left={left}
      top={top}
      mobileLeft={mobileLeft}
      mobileTop={mobileTop}
    >
      {isMobile ? (
        <MobileDotWrapper as={Link} to={url}>
          <MobileDot />
        </MobileDotWrapper>
      ) : (
        <QuickView
          handle={handle}
          parentProductHandle={parentProductHandle}
          layout="catalogue"
        />
      )}
    </Content>
  ),
)
