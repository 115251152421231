import React from "react"
import { useRoutes } from "../../../../hooks/useRoutes"

export const withCatalogueGridCard = Component => ({
  name = "CatalogueGridCard",
  className = undefined,
  card,
  handlePromoClick,
}) => {
  const { linkResolver } = useRoutes()
  const { image: imageVideo, content, contentPosition, products, desktopMaxWidth, mobileMaxWidth } = card || {}
  const { left, top, mobileLeft, mobileTop } = contentPosition || {}
  const link = linkResolver(imageVideo?.link)

  Component.displayName = name
  return (
    <Component
      className={className}
      handlePromoClick={handlePromoClick}
      imageVideo={imageVideo}
      link={link}
      left={left}
      top={top}
      mobileLeft={mobileLeft}
      mobileTop={mobileTop}
      desktopMaxWidth={desktopMaxWidth}
      mobileMaxWidth={mobileMaxWidth}
      content={content}
      products={products}
    />
  )
}
