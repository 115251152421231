import React from "react"
import { useApp } from "../../../../../hooks/useApp"
import { useShopify } from "../../../../../hooks/useShopify"
import { useWindowSize } from "../../../../../hooks/useWindowSize"

export const withCatalogueGridCardProduct = Component => ({
  name = "CatalogueGridCardProduct",
  item,
}) => {
  const { getHandle } = useShopify()
  const { windowSize } = useWindowSize()
  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const { position, product } = item || {}

  const handle = getHandle(product)
  // const handle = "303-endura-boot-303-redwood"

  const isParentProduct = product?.tags?.some(tag => tag === "parent")
  const childProductHandles = (product?.tags || [])
    .filter(tag => tag.includes("colours"))
    .map(tag => tag?.split(":")?.[1])

  const { top, left, mobileTop, mobileLeft } = position || {}

  const isMobile = windowSize.width <= 768

  const url = `${routes.PRODUCT}/${
    isParentProduct ? childProductHandles[0] : handle
  }`

  Component.displayName = name
  return (
    <Component
      position={position}
      left={left}
      top={top}
      mobileLeft={mobileLeft}
      mobileTop={mobileTop}
      handle={isParentProduct ? childProductHandles[0] : handle}
      parentProductHandle={isParentProduct ? handle : undefined}
      isMobile={isMobile}
      url={url}
    />
  )
}
